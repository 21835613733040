import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { useRollbar } from "@rollbar/react";

// Dispatch actions
import { cancelBooking, confirmBooking } from "../../store/actions/bookings";

// Helpers and utils
import { getSearchParams } from "../../utils/params";
import { titleImage } from "../../utils/formatters";
import routes, { routeWithParams } from "../../routes";

// Components
import { PageLayout, Overlay, Heading3, Paragraph } from "../../components";
import Loading from "../../components/Loading";
import Success from "../Checkout/Success";
import FailOrLoading from "./FailOrLoading";

const MAX_PAYMENT_CHECKS = 5;

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;

const Checkout = ({ intl, booking, provider }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const rollbar = useRollbar();

  // Retrive booking id at first
  const { id } = useParams();

  // Propagate processing / confirming to prevent cancel
  const [confirming, setConfirming] = useState(false);

  // Final success / error state
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(null);

  // Confirm callback
  const onSuccess = useCallback(
    paymentIntent => {
      // Handle successful payment
      setConfirming(true);

      // If redirect and final check indicates success, confirm the booking and set state to success
      // to render success message to user
      dispatch(confirmBooking(booking, paymentIntent))
        .then(confirmedBooking => {
          if (confirmedBooking.id) {
            setSuccess(true);
          }

          setConfirming(false);
        })
        .catch(error => {
          rollbar.error("Error confirming booking", error, { booking });
          setConfirming(false);
        });
    },
    [booking, dispatch, rollbar],
  );

  // Run this effect to check the status and then confirm the booking
  useEffect(() => {
    if (!booking || success || confirming) {
      return;
    }

    // If this booking is succeded already, just show message
    if (booking.status === "paid") {
      return setSuccess(true);
    }

    if (!stripe) {
      // Wait for stripe to finish loading
      console.warn("Stripe promise not present!");
      return;
    }

    const { redirect_status, payment_intent_client_secret } = getSearchParams() || {};
    let paymentCheckRetry = 0;

    const checkPaymentIntent = async clientSecret => {
      const { paymentIntent, error } = await stripe.retrievePaymentIntent(clientSecret);
      if (error) {
        rollbar.error("StripeError retrieving pamyent intent", error, { booking });
        return alert(intl.formatMessage({ id: "components.Booking.error.payment" }));
      } else if (
        paymentIntent &&
        (paymentIntent.status === "succeeded" || paymentIntent.status === "processing")
      ) {
        // Stripes payment intent succeded meanwhile, good to go!
        onSuccess(paymentIntent);
      } else if (paymentIntent && paymentIntent.status === "pending") {
        // If the payment wasn't processed within 15 seconds we assume it succeeded anyways
        if (paymentCheckRetry >= MAX_PAYMENT_CHECKS) {
          return onSuccess(paymentIntent);
        }
        window.setTimeout(() => checkPaymentIntent(payment_intent_client_secret), 5000);
        paymentCheckRetry++;
        console.log(`PaymentIntent not processed yet, retry (${paymentCheckRetry})...`);
      } else {
        // Unknown status?
        return alert(intl.formatMessage({ id: "components.Booking.error.payment" }));
      }
    };

    if (redirect_status === "canceled") {
      // Ignore?
      setFailure(redirect_status);
    } else if (redirect_status === "failed") {
      // Error message?
      setFailure(redirect_status);
    } else if (
      (redirect_status === "succeeded" ||
        redirect_status === "pending" ||
        redirect_status === "processing") &&
      payment_intent_client_secret
    ) {
      setConfirming(true);
      setFailure(null);

      checkPaymentIntent(payment_intent_client_secret);
    }

    return () => {};
  }, [booking, id, stripe, intl, dispatch, success, confirming, onSuccess, rollbar]);

  const onCancel = () => {
    dispatch(cancelBooking(booking.status === "paid" ? null : booking));

    return history.push(
      routeWithParams(routes.course, { slug: booking.provider.slug, id: booking.course.id }),
    );
  };

  if (!booking) {
    return (
      <FailOrLoading
        failure={failure}
        returnUrl={routeWithParams(routes.courses, { slug: provider.slug })}
      />
    );
  }

  // Final state, show success message
  return (
    <PageLayout>
      {success ? (
        <Success image={titleImage(booking.course)} onClose={onCancel}>
          <Heading3>{booking.course.name}</Heading3>
          <Paragraph>
            <strong>{booking.event.name}</strong>
          </Paragraph>
        </Success>
      ) : (
        <Overlay blackout>
          {confirming ? (
            <LoadingWrapper>
              <Loading text={<FormattedMessage id="components.Booking.loading.confirm" />} />
            </LoadingWrapper>
          ) : (
            <LoadingWrapper>
              <Loading text={<FormattedMessage id="components.Booking.loading.payment" />} />
            </LoadingWrapper>
          )}
        </Overlay>
      )}
    </PageLayout>
  );
};

export default injectIntl(Checkout);
