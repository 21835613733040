import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { RichText, Button, Heading3, Icon, Paragraph } from "../../components/";
import VimeoVideo from "./VimeoVideo";
import { DEFAULT_TIMEZONE } from "../../utils/calc";

const Wrapper = styled.section`
  padding: 0.5rem 1rem;
  flex: 1;

  @media (${props => props.theme.tabletScreen}) {
    padding: 1.5rem 2.5rem;
  }
`;

const Actions = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: center;
`;

const PageButton = styled(Button)`
  text-align: left;
  box-shadow: ${props => props.theme.boxShadow};
  text-transform: none;

  hover: {
    background-color: ${props => props.theme.light};
  }
`;

const NextButton = styled(Button)`
  & > span {
    white-space: nowrap;
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;

    @media (${props => props.theme.tabletScreen}) {
      max-width: 240px;
    }
  }
  & > i {
    margin-left: 1rem;
  }
`;

const PrevButton = styled(Button)`
  & > span {
    display: none;
    @media (${props => props.theme.tabletScreen}) {
      display: inline;
    }
  }
  & > i {
    @media (${props => props.theme.tabletScreen}) {
      margin-right: 1rem;
    }
  }
`;

const Files = styled.section`
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-top: 1rem;
  border-top: 1px solid ${props => props.theme.light};
`;

const FileName = styled.span`
  margin-right: 1rem;
`;

const PagesDropdown = styled.div`
  position: relative;
  user-select: none;
  margin-bottom: 1rem;
`;

const PagesDropdownItems = styled.ol`
  position: absolute;
  top: 100%;
  margin-top: 1rem;
  min-width: 280px;
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  border-radius: ${props => props.theme.borderRadius};
  z-index: 2;
  overflow: auto;

  transition: max-height 0.125s ease-out;
`;

const Poster = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  aspect-ratio: 16/9;
  border-radius: ${props => props.theme.borderRadiusSmall};
  overflow: hidden;
`;

const Title = styled(Heading3)`
  font-size: ${props => props.theme.fontSizes.large};

  @media (${props => props.theme.tabletScreen}) {
    font-size: 1.75rem;
  }
`;

const EmbedCodeWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  border-radius: ${props => props.theme.borderRadiusSmall};
  background-color: ${props => props.theme.light};

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    overflow: hidden;
  }
`;

const EmbedCode = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 0;
  overflow: hidden;
`;

const NotPublished = styled.section`
  font-size: ${props => props.theme.fontSizes.large};
  background-color: ${props => props.theme.light};
  border-radius: ${props => props.theme.borderRadius};
  padding: 1rem;
  margin: 1rem 2rem;
  text-align: center;

  @media (${props => props.theme.tabletScreen}) {
    padding: 2rem;
    margin: 2rem 4rem;
  }
`;

const PublishAt = styled.strong`
  font-size: 1.8rem;
  font-family: ${props => props.theme.fontFamilyBrand};
`;

const Page = ({ page, room }) => {
  const [navOpen, setNavOpen] = useState(false);

  const history = useHistory();

  const navigateTo = page => {
    history.push(`?page=${page.id}`);
  };

  const videoContent = () => {
    if (!page) {
      return "";
    }

    if (page.vimeoVideoId) {
      return <VimeoVideo link={page.vimeoVideoLink} />;
    } else if (page.embedCode) {
      return page.embedCode.startsWith("<iframe") ? (
        <EmbedCodeWrapper dangerouslySetInnerHTML={{ __html: page.embedCode }} />
      ) : (
        <EmbedCodeWrapper>
          <EmbedCode srcDoc={page.embedCode} />
        </EmbedCodeWrapper>
      );
    } else if (page.poster) {
      return <Poster style={{ backgroundImage: `url(${page.poster})` }} />;
    } else {
      return "";
    }
  };

  const index = (room.pages || []).indexOf(page);

  return (
    <Wrapper>
      <PagesDropdown onClick={() => setNavOpen(!navOpen)}>
        <Title>
          {page.title}
          <Icon name={`chevron-${navOpen ? "up" : "down"}`} spaced="left" />
        </Title>
        <PagesDropdownItems style={{ maxHeight: navOpen ? "380px" : "0" }}>
          {room.pages.map(otherPage => (
            <PageButton
              key={otherPage.id}
              onClick={() => navigateTo(otherPage)}
              style={{
                margin: "0.25rem",
                boxShadow: "none",
                opacity: otherPage.published ? 1 : 0.5,
              }}
              color={otherPage === page ? "primary" : "white"}
              glow={false}
            >
              {otherPage.title}
              <span>
                {otherPage.publishAt && <Icon name="alarm-clock" size="small" spaced="left" />}
                {otherPage.contentFile && <Icon name="paperclip" size="small" spaced="left" />}
                {otherPage.vimeoVideoId && <Icon name="film" size="small" spaced="left" />}
              </span>
            </PageButton>
          ))}
        </PagesDropdownItems>
      </PagesDropdown>
      {page.published ? (
        <main>
          <section>{videoContent()}</section>

          <RichText text={page.content || ""} />

          {page.contentFile && (
            <Files>
              <strong>
                <FormattedMessage id="pages.Room.files" />
              </strong>
              <br />
              <FileName>{page.contentFile.name}</FileName>
              <Button onClick={() => window.open(page.contentFile.url, "_blank").focus()}>
                <Icon name="download" />
                <FormattedMessage id="actions.download" />
              </Button>
            </Files>
          )}
        </main>
      ) : (
        <main>
          <NotPublished>
            {page.publishAt ? (
              <Paragraph>
                <Icon name="alarm-clock" size="huge" />
                <br />
                <br />
                <FormattedMessage id="pages.Room.publishAt" />
                <br />
                <PublishAt>
                  <FormattedDate month="short" day="2-digit" value={Date.parse(page.publishAt)} />
                  {", "}
                  <FormattedTime value={Date.parse(page.publishAt)} timeZone={DEFAULT_TIMEZONE} />
                </PublishAt>
                <br />
                <br />
                <Button
                  color="primary"
                  onClick={e => {
                    e.preventDefault();
                    window.location.reload();
                  }}
                >
                  <FormattedMessage id="actions.reload" />
                </Button>
              </Paragraph>
            ) : (
              <FormattedMessage id="pages.Room.notPublishedYet" />
            )}
          </NotPublished>
        </main>
      )}
      <Actions>
        {room.pages[index - 1] ? (
          <PrevButton onClick={() => navigateTo(room.pages[index - 1])}>
            <Icon name="chevron-up" direction="left" size="small" />
            <FormattedMessage id="actions.back" />
          </PrevButton>
        ) : (
          <span></span>
        )}
        {room.pages[index + 1] ? (
          <NextButton onClick={() => navigateTo(room.pages[index + 1])} color="primary">
            <FormattedMessage id="actions.nextTo" values={{ that: room.pages[index + 1].title }} />
            <Icon name="chevron-up" direction="right" size="small" />
          </NextButton>
        ) : (
          <NextButton onClick={() => navigateTo(room.pages[0])} color="default">
            <FormattedMessage id="pages.Room.restart" />
          </NextButton>
        )}
      </Actions>
    </Wrapper>
  );
};

Page.propTypes = {
  page: PropTypes.object.isRequired,
};

export default Page;
