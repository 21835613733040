import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { createContract, login } from "../../../../store/actions/user";
import { getUserData } from "../../../../store/selectors/user";

import Confirm from "./Confirm";

const mapStateToProps = state => ({
  userData: getUserData(state),
});

const mapDispatchToProps = { createContract, login };

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(props => <Confirm {...props} />),
);
