import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import theme from "../../theme";

const Checkout = ({ booking, onSuccess, onProcessingStateChange, onCancel }) => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_API_KEY,
    booking.stripeAccount ? { stripeAccount: booking.stripeAccount } : {},
  );

  // https://docs.stripe.com/elements/appearance-api
  // Either create with payment intent or with inline payment info like
  // const options = {
  //   mode: "payment",
  //   amount: booking.costs * 100,
  //   currency: booking.currency?.toLocaleLowerCase() || "eur",
  const options = {
    clientSecret: booking.clientSecret,
    // Stripe elements styling
    appearance: {
      theme: "flat",
      variables: {
        colorPrimary: theme.info,
        colorBackground: theme.light,
        colorText: theme.dark,
        colorDanger: theme.danger,
        fontFamily: theme.fontFamilyBase,
        spacingUnit: "4px",
        borderRadius: theme.borderRadius,
      },
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckoutForm booking={booking} onCancel={onCancel} onSuccess={onSuccess} />
    </Elements>
  );
};

export default Checkout;
