import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from "react-intl";
import { camelCase } from "lodash";

import { titleImage, truncate, currencyFormat } from "../utils/formatters";
import { EVENT_TYPES } from "../utils/courses";
import routes, { routeWithParams } from "../routes";
import Icon from "./Icon";
import { Paragraph, Mono } from "./";
import { DEFAULT_TIMEZONE } from "../utils/calc";
import {
  Header,
  Title,
  Details,
  DetailItem,
  Content,
  Wrapper,
  DateWrapper,
  TypeBadges,
  TypeBadge,
  RatingsHeart,
} from "./CourseCard";

const descriptionLength = () => (window.innerWidth > 500 ? 240 : 140);

const EventCard = ({ event, course, provider, eventRoute, showPrices }) => {
  const timingDetails = () => {
    const typeOf = event.typeOf;
    switch (typeOf) {
      case EVENT_TYPES.flexible:
        return event.description ? (
          event.description
        ) : (
          <FormattedMessage id="components.Course.flexibleEvents" />
        );
      case EVENT_TYPES.continuous:
        if ((event.appointments || []).length > 0) {
          return event.appointments.map(({ startDate, endDate }) => (
            <span key={`date-${startDate}`}>
              <FormattedDate weekday="short" value={Date.parse(startDate)} />
              {", "}
              <FormattedTime value={Date.parse(startDate)} timeZone={DEFAULT_TIMEZONE} />
              -
              <FormattedTime value={Date.parse(endDate)} timeZone={DEFAULT_TIMEZONE} />
              <br />
            </span>
          ));
        }
        return event.description !== "" ? (
          event.description
        ) : (
          <FormattedMessage id="components.Course.continousEvents" />
        );
      default:
        if (event.startDate) {
          return (
            <>
              <DateWrapper past={Date.parse(event.startDate) < new Date()}>
                <FormattedDate month="long" day="2-digit" value={Date.parse(event.startDate)} />
              </DateWrapper>
              {" - "}
              <FormattedDate month="long" day="2-digit" value={Date.parse(event.endDate)} />
            </>
          );
        }
        return "-";
    }
  };

  const imageSize = "display";

  return (
    <Wrapper>
      <NavLink
        to={routeWithParams(eventRoute, { slug: course.provider.slug, id: course.id })}
        target={provider && "_blank"}
      >
        <Header
          style={{
            minHeight: titleImage(course, imageSize) ? "200px" : "0",
            backgroundImage: titleImage(course, imageSize)
              ? `url(${titleImage(course, imageSize)})`
              : "none",
          }}
        ></Header>
        <Content>
          <header style={{ position: "relative" }}>
            <Title>
              {event.name}
              <br />
              <small>{course.name}</small>
            </Title>
            <Paragraph>{truncate(event.description || "", descriptionLength())}</Paragraph>
            <TypeBadges>
              {course.flexibleLocation && (
                <TypeBadge online>
                  <FormattedMessage
                    id={`course.attributes.locationType.${camelCase(
                      course.locationType || "online",
                    )}`}
                  />
                </TypeBadge>
              )}
              {course.heartsCount >= 1 && (
                <TypeBadge rating>
                  <span>{`${course.heartsCount} `}</span>
                  <RatingsHeart>
                    <Icon name="heart" color="danger" size="small" />
                  </RatingsHeart>
                </TypeBadge>
              )}
            </TypeBadges>
          </header>
          <section>
            <Details>
              <DetailItem>
                <Icon name="calendar-day" size="small" />
                <Mono>{timingDetails()}</Mono>
              </DetailItem>
              {showPrices && (
                <DetailItem>
                  <Icon name="tags" size="small" />
                  <Mono>
                    {course.priceFrom ? (
                      <span>
                        {course.priceTo && course.priceFrom !== course.priceTo && (
                          <FormattedMessage id="priceFrom" />
                        )}
                        <FormattedNumber
                          value={course.priceFrom / 100}
                          {...currencyFormat(course)}
                        />
                      </span>
                    ) : course.priceFrom !== course.priceTo ? (
                      <span>
                        <FormattedMessage id="priceFrom" />
                        <FormattedNumber value={0} {...currencyFormat(course)} />
                      </span>
                    ) : (
                      <FormattedMessage id="components.Course.free" />
                    )}
                  </Mono>
                </DetailItem>
              )}
            </Details>
          </section>
        </Content>
      </NavLink>
    </Wrapper>
  );
};

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  provider: PropTypes.object,
  eventRoute: PropTypes.string,
  showPrices: PropTypes.bool,
};

EventCard.defaultProps = {
  provider: null,
  eventRoute: routes.course,
  showPrices: false,
};

export default EventCard;
