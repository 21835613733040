import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { getSearchParams } from "../../utils/params";
import routes, { routeWithParams } from "../../routes";

import { PageLayout, Icon, Row, Col, Paragraph, Button } from "../../components";
import Loading from "../../components/Loading";
import Booking from "../../components/Booking/";
import PriceOptions from "../Course/PriceOptions";
import { BookingOverlay, BookingWrapper } from "../Checkout";
import { scrollTop } from "../../utils/common";
import { PRODUCT_MODEL } from "../Course/Event";

import Title from "./Title";
import EventCard from "../../components/EventCard";
import { useRollbar } from "@rollbar/react";

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;
const CancelButton = styled.button`
  border: 0;
  padding: 0.4rem 0.5rem;
  background-color: ${props => props.theme.white};
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  color: ${props => props.theme.dark};
  cursor: pointer;
  z-index: 1;
  &:hover {
    color: ${props => props.theme.black};
  }
`;

const Actions = styled.div`
  margin-top: 2rem;
  text-align: center;

  @media (${props => props.theme.tabletScreen}) {
    text-align: left;
  }
`;

const EventCardWrapper = styled.div`
  min-width: 280px;
  width: 100%;
  margin: 1rem auto;

  @media (${props => props.theme.tabletScreen}) {
    max-width: 320px;
  }
`;

const Event = ({
  provider,
  course,
  match,
  userData,
  intl,
  cancelBooking,
  confirmBooking,
  loadCourse,
  origin,
}) => {
  const history = useHistory();
  const rollbar = useRollbar();

  // Booking states
  const [event, setEvent] = useState(null);
  const [priceOption, setPriceOption] = useState(null);

  // Load course if not yet done
  useEffect(() => {
    if (course) {
      return setEvent(course.events.find(e => e.id === parseInt(match.params.id)));
    }

    loadCourse(provider.id, match.params.courseId, (getSearchParams() || {}).token);

    return () => {
      setEvent(null);
    };
  }, [provider, course, match, loadCourse]);

  // Done loading, set price options and other pre-filled settings
  useEffect(() => {
    if (!event) {
      return;
    }

    const priceOptionId = (getSearchParams() || {}).priceOption;
    const pO = event.priceOptions.find(p => p.id === priceOptionId);

    if (pO) {
      setPriceOption(pO);
    } else {
      if (
        priceOption &&
        window.confirm(intl.formatMessage({ id: "components.Booking.cancelBookingConfirm" }))
      ) {
        setPriceOption(null);
      }
    }

    scrollTop();
  }, [event, priceOption, match, intl]);

  const onCancel = () => {
    // If ongoing, and not processing confirm cancel
    if (priceOption) {
      if (window.confirm(intl.formatMessage({ id: "components.Booking.cancelBookingConfirm" }))) {
        cancelBooking();
        return history.push(
          routeWithParams(routes.course, { slug: course.provider.slug, id: course.id }),
        );
      } else {
        return;
      }
    }

    history.push(routeWithParams(routes.course, { slug: course.provider.slug, id: course.id }));
  };

  // Callback for booking success, check for clientSecret to proceed to checkout
  const onBookingSuccess = async booking => {
    // If booking is for free, considered as succeeded
    if (booking.costs <= 0) {
      try {
        await confirmBooking(booking, {});
      } catch (error) {
        const { response } = error;
        rollbar.error("Error confirming booking", error, { booking, response });
      }
    }

    // If not for free, forward to checkout
    return history.push(
      routeWithParams(routes.booking.checkout, { slug: booking.provider.slug, id: booking.id }),
    );
  };

  const handlePriceOptionClick = priceOption => {
    if (!priceOption) {
      return;
    }

    if (priceOption.model === PRODUCT_MODEL) {
      return history.push(
        routeWithParams(
          routes.contracts.new,
          {
            id: priceOption.id,
          },
          {
            from: routeWithParams(routes.course, {
              slug: course.provider.slug,
              id: course.id,
            }),
            courseId: course.id,
            eventId: event.id,
          },
        ),
      );
    }

    history.push(
      routeWithParams(
        routes.event,
        {
          slug: course.provider.slug,
          courseId: course.id,
          id: event.id,
        },
        { priceOption: priceOption.id, token: (getSearchParams() || {}).token },
      ),
    );
  };

  if (course && event) {
    // Price option available? Proceed to booking form

    const isBookable =
      course.isBookable &&
      ((event.priceOptions || []).length > 0 || (event.products || []).length > 0);

    return (
      <PageLayout>
        <BookingOverlay blackout topAligned onClose={onCancel}>
          <BookingWrapper>
            <CancelButton onClick={onCancel}>
              <Icon name="add" direction={45} />
            </CancelButton>
            {priceOption ? (
              <Booking
                course={course}
                event={event}
                priceOption={priceOption}
                onSuccess={onBookingSuccess}
                onCancel={onCancel}
              />
            ) : (
              <>
                <Title course={course} event={event} />
                <Paragraph>
                  <FormattedMessage id="pages.Event.missingPrice" />
                </Paragraph>
                <Row>
                  <Col count={6}>
                    <EventCardWrapper>
                      <EventCard event={event} course={course} />
                    </EventCardWrapper>
                  </Col>
                  <Col count={6}>
                    <PriceOptions
                      event={event}
                      course={course}
                      isBookable={isBookable}
                      onClick={handlePriceOptionClick}
                      showPriceOptions
                    />
                  </Col>
                </Row>
                <Actions>
                  <Button
                    to={routeWithParams(routes.course, {
                      slug: course.provider.slug,
                      id: course.id,
                    })}
                  >
                    <FormattedMessage id="backTo" values={{ that: course.name }} />
                  </Button>
                </Actions>
              </>
            )}
          </BookingWrapper>
        </BookingOverlay>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <LoadingWrapper>
        <Loading text={<FormattedMessage id="loading" />} />
      </LoadingWrapper>
    </PageLayout>
  );
};

Event.propTypes = {
  match: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  userData: PropTypes.object,
  // Dispatch: PropTypes.func.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  confirmBooking: PropTypes.func.isRequired,
};

Event.defaultProps = {
  userData: null,
};

export default injectIntl(Event);
