import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { PaymentRequestButtonElement } from "@stripe/react-stripe-js";

const Wrapper = styled.div`
  margin-bottom: 0.25rem;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
`;

export default ({ booking, stripe, onSuccess, onError }) => {
  const { course, event } = booking;
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (!stripe || !booking) {
      return;
    }

    const paymentRequest = stripe.paymentRequest({
      country: "DE",
      currency: "eur",
      total: {
        label: [course.name, event.name].join(" "),
        amount: parseInt(booking.costs * 100),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    paymentRequest.on("paymentmethod", async ev => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        booking.clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete("fail");
        onError("Confirm Error");
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete("success");
        // Let Stripe.js handle the rest of the payment flow.
        onSuccess(paymentIntent);
      }
    });

    // Check the availability of the Payment Request API.
    paymentRequest
      .canMakePayment()
      .then(result => {
        if (result) {
          setPaymentRequest(paymentRequest);
        }
      })
      .catch(error => {
        console.error("Cant request Payment Buttons");
        console.error(error);
      });
  }, [stripe, booking, course, event, onSuccess, onError]);

  if (paymentRequest) {
    return (
      <Wrapper>
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      </Wrapper>
    );
  }

  // Use a traditional checkout form.
  return "";
};
