import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { FormattedMessage } from "react-intl";
import routes from "../../routes";
import { getSearchParams } from "../../utils/params";

import { ApiClient } from "../../utils/Api";
import { scrollTop } from "../../utils/common";
import { gradientColors } from "../../utils/formatters";
import { Button, Icon } from "../../components/";
import Loading from "../../components/Loading";
import Map from "../../components/Map";
import officialPartner from "../../assets/official-kikudoo-partner-pill.png";
import { locationsFromProviders } from "../Franchise";

const MIN_ZOOM = 6;
const MAX_ZOOM = 16;

const aniBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Wrapper = styled.div`
  min-width: 100%;
  min-height: 100vh;
  position: relative;

  background-image: linear-gradient(-106deg, ${props =>
    props.backgroundColor && props.backgroundColor !== "#000000"
      ? gradientColors(props.backgroundColor, 12, false)
      : "#6f42c1, #e16c6c"});
  background-size: ${props => (props.backgroundColor ? "100% 100%" : "500% 500%")};
  transition: background-image 2s;
  // animation: ${aniBackground} 20s ease infinite;

  display: flex;
  flex-direction: column;
`;

const LoadingWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FranchiseMap = styled(Map)`
  min-height: 100%;
  min-width: 100%;
  flex: 1;

  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
  box-shadow: ${props => props.theme.boxShadowSmall};
  background: ${props => props.theme.gray100};
  position: relative;
`;

const MapWrapper = styled.section`
  position: relative;
  border-radius: ${props => props.theme.borderRadius};
  overflow: hidden;
  margin: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const MapOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 401;
  display: flex;
  justify-content: center;
  align-items: center;
  & > span {
    padding: 1rem;
    background-color: ${props => props.theme.white};
    box-shadow: ${props => props.theme.boxShadowSmall};
    border-radius: ${props => props.theme.borderRadius};
    text-align: center;
    opacity: 0.75;
    transition: opacity 0.25s;
  }

  &:hover > span {
    opacity: 0.75;
  }

  @media (${props => props.theme.tabletScreen}) {
    & > span {
      opacity: 0;
    }
  }
`;

const PartnerBadge = styled.aside`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 412;
  max-width: 200px;
  & > img {
    display: block;
    margin: 0 0 0 auto;
    max-width: 270px;
    min-width: 120px;
    width: 100%;
  }

  @media (${props => props.theme.tabletScreen}) {
    max-width: 100%;
  }
`;

const SearchCTA = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 412;

  i {
    vertical-align: middle;
    margin-right: 6px;
  }
`;

const Franchise = ({ match }) => {
  const [franchise, setFranchise] = useState(null);
  const [providers, setProviders] = useState(null);
  const [error, setError] = useState(null);
  const [mapEnabled, setMapEnabled] = useState(false);

  const params = getSearchParams() || {};
  const lat = (params.lat && parseFloat(params.lat)) || 51.165691;
  const lng = (params.lng && parseFloat(params.lng)) || 10.451526;
  const defaultZoom = (params.zoom && parseFloat(params.zoom)) || 6;
  const showSearchButton = false;

  useEffect(() => {
    scrollTop();

    async function getFranchise(id) {
      try {
        const franchise = await new ApiClient().franchise(id);
        setFranchise(franchise);
      } catch (e) {
        setError(404);
      }
    }

    if (!match.params.id) {
      return;
    }

    getFranchise(match.params.id);
    return () => {
      setFranchise(null);
    };
  }, [match, setFranchise, setError]);

  useEffect(() => {
    if (!franchise) {
      return;
    }

    async function getProviders(id) {
      try {
        const providers = await new ApiClient().franchiseProviders(id);
        setProviders(providers);
      } catch (e) {
        setError("Not found");
      }
    }

    getProviders(franchise.id);

    return () => {
      setProviders(null);
    };
  }, [franchise, setProviders]);

  if (!franchise) {
    return (
      <LoadingWrapper>
        {error ? (
          <FormattedMessage id={error === 404 ? "notFound" : "error"} />
        ) : (
          <Loading text={<FormattedMessage id="loading" />} />
        )}
      </LoadingWrapper>
    );
  }

  const onProviderClick = provider => {
    if (!provider) {
      return;
    }
    const win = window.open(`/${provider.slug}`, "_blank");
    if (win && win.focus) {
      win.focus();
    }
  };

  const locations = locationsFromProviders(
    (providers || []).filter(
      provider => provider.address && provider.address.lat && provider.address.lng,
    ),
  );

  return (
    <Wrapper backgroundColor="#fff">
      <MapWrapper>
        <PartnerBadge onClick={() => window.open("https://kikudoo.com/pages/partners")}>
          <img src={officialPartner} alt={"Official Partner of kikudoo"} />
        </PartnerBadge>
        {showSearchButton && (
          <SearchCTA>
            <Button
              color="info"
              glow
              round
              onClick={() =>
                window.open(`https://kikudoo.com${routes.search}?term=${franchise.name}`)
              }
            >
              <Icon name="magnifying-glass" color="white" />
              <FormattedMessage id="pages.Franchise.providers.search" />
            </Button>
          </SearchCTA>
        )}

        {!mapEnabled && (
          <MapOverlay onClick={() => setMapEnabled(true)}>
            <FormattedMessage id="pages.SearchMap.activaMap"></FormattedMessage>
          </MapOverlay>
        )}

        {providers && (
          <FranchiseMap
            options={{
              minZoom: MIN_ZOOM,
              maxZoom: MAX_ZOOM,
            }}
            defaultViewport={{ center: [lat, lng], zoom: defaultZoom }}
            onMarkerClick={location => onProviderClick((location.providers || [])[0])}
            locations={locations}
          />
        )}
      </MapWrapper>
    </Wrapper>
  );
};

Franchise.propTypes = {
  match: PropTypes.object.isRequired,
};

Franchise.defaultProps = {};

export default Franchise;
