import React from "react";
import { PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import { getApiClient } from "../../utils/Api";
import { useDispatch } from "react-redux";
import { bookingSuccess } from "../../store/actions/bookings";
import { injectIntl } from "react-intl";

// This value is from the props in the UI
const style = { layout: "vertical" };

// Custom component to wrap the PayPalButtons and show loading spinner
const ButtonWrapper = ({ booking, onSuccess, onCancel, intl }) => {
  const dispatch = useDispatch();

  const createOrder = async () => {
    try {
      const updatedBooking = await getApiClient().bookingPayment(booking.id, "paypal");

      if (updatedBooking) {
        dispatch(bookingSuccess(updatedBooking));

        return updatedBooking.paypalOrderId;
      }
    } catch (error) {
      if (error.isAxiosError) {
        const response = error.response;
        switch (response.status) {
          case 422:
            return window.alert(intl.formatMessage({ id: "components.PaypalButton.error.amount" }));
          case 400:
            return window.alert(intl.formatMessage({ id: "components.PaypalButton.error.type" }));
          case 409:
            return window.alert(intl.formatMessage({ id: "components.PaypalButton.error.status" }));
          default:
            return window.alert(
              intl.formatMessage({ id: "components.PaypalButton.error.createOrder" }),
            );
        }
      }

      window.alert(intl.formatMessage({ id: "components.PaypalButton.error.createOrder" }));
    }
  };

  const onApprove = data => onSuccess(data);

  return (
    <>
      <PayPalButtons
        style={style}
        disabled={false}
        forceReRender={[style]}
        fundingSource={FUNDING.PAYPAL}
        createOrder={createOrder}
        onApprove={onApprove}
        onCancel={onCancel}
      />
    </>
  );
};

export default injectIntl(ButtonWrapper);
