import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { useRollbar } from "@rollbar/react";
import { useParams } from "react-router";

// Selectors
import { getCurrentBooking } from "../../store/selectors/bookings";
import { getCurrentProvider } from "../../store/selectors/provider";

// Dispatch actions
import { loadBooking } from "../../store/actions/bookings";

// Helpers and utils
import routes, { routeWithParams } from "../../routes";

// Components
import CheckoutRedirect from "./CheckoutRedirect";
import FailOrLoading from "./FailOrLoading";

const CheckoutRedirectWrapper = () => {
  const dispatch = useDispatch();
  const rollbar = useRollbar();

  // Retrive booking id at first
  const { id } = useParams();

  // Get the booking if it is in state - on session
  const [failure, setFailure] = useState(null);
  const provider = useSelector(state => getCurrentProvider(state));
  const booking = useSelector(state => getCurrentBooking(state));

  // Initial booking fetch, it is either in store already or has to be fetched from api
  useEffect(() => {
    if (booking && booking.id === id) {
      // Don't fetch booking again
      return;
    }

    // Fetch booking if not in store
    dispatch(loadBooking(id)).catch(error => {
      if (error && error.isAxiosError) {
        setFailure(error.response.status);
      } else {
        setFailure("generic");
      }
      rollbar.error("Error loading booking", error, { bookingId: id });
    });
    return () => {};
  }, [booking, id, dispatch, rollbar]);

  // If booking is not yet loaded or failed show state
  if (!booking) {
    return (
      <FailOrLoading
        failure={failure}
        returnUrl={routeWithParams(routes.courses, { slug: provider.slug })}
      />
    );
  }

  // We need to wait until booking is present before knowing if the booking uses
  // direct-charges and needs a stripe connect account id
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_API_KEY,
    booking.stripeAccount ? { stripeAccount: booking.stripeAccount } : {},
  );

  return (
    <Elements stripe={stripePromise}>
      <CheckoutRedirect booking={booking} provider={provider} />
    </Elements>
  );
};

export default CheckoutRedirectWrapper;
