import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedHTMLMessage, FormattedMessage, FormattedNumber } from "react-intl";
import { currencyFormat, titleImage, truncate } from "../../utils/formatters";
import { Small, Paragraph, Heading3, Icon } from "../";
import { calcBaseCosts } from "./Booking";

const Wrapper = styled.section`
  position: relative;
  @media (${props => props.theme.tabletScreen}) {
    padding-left: 1rem;
  }
`;

const Table = styled.table`
  margin-top: ${props => (props.expanded ? "0" : "-1rem")};
  width: 100%;
  th {
    vertical-align: bottom;
    padding: 0.75rem;
    border-bottom: 2px solid ${props => props.theme.gray300};
    border-top: 0;

    &:last-child {
      text-align: right;
      white-space: nowrap;
    }
  }

  td {
    padding: 0.75rem 0.75rem;
    vertical-align: top;
    border-top: ${props => (props.expanded ? "1px" : "0")} solid ${props => props.theme.gray300};

    &:last-child {
      text-align: right;
      font-family: ${props => props.theme.fontFamilyMonospace};
      width: 34%;
      white-space: nowrap;
    }
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 2rem;
  }

  // Android Chrome soft keyboard
  @media (max-height: 400px) {
    display: none;
  }
`;

const CostsTotal = styled.span`
  color: ${props => props.theme.black};
  font-size: 1rem;
`;

const CourseSummary = styled.div`
  display: none;
  @media (${props => props.theme.tabletScreen}) {
    display: block;
    text-align: center;
  }
`;

const TitleImage = styled.div`
  background-image: url('${props => props.src}');
  background-color: ${props => props.theme.light};
  background-size: cover;
  width: 50%;
  margin: 0 auto 1rem;
  &:after {
    content: "";
    display: block;
    padding-top: 75%;
  }
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius};
`;

const ExpandButton = styled.button`
  border: 0;
  padding: 0.4rem 0.65rem 0.4rem;
  background-color: ${props => props.theme.white};
  box-shadow: 0 -1rem 2rem -0.3rem rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -2.2rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  color: ${props => props.theme.gray500};
  @media (${props => props.theme.tabletScreen}) {
    display: none;
    box-shadow: none;
  }
`;

const InstructionHint = styled.span`
  text-align: center;
  display: block;
  color: ${props => props.theme.black};

  // Android Chrome soft keyboard
  @media (max-height: 420px) {
    display: none;
  }
`;

const VatRate = styled(Small)`
  display: block;
`;

const PriceOptionName = styled.small`
  color: ${props => props.theme.info};
  white-space: normal;
`;

const Trainers = styled.span`
  display: block;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const BookingItem = styled.small`
  margin-left: 0.5rem;
`;

const Summary = ({
  participants,
  costs,
  serviceFee,
  voucher,
  priceOption,
  course,
  event,
  provider,
  bookingItemValues,
}) => {
  const nonMobile = window.innerWidth >= 576;
  const [expanded, setExpanded] = useState(nonMobile);
  const showParticipantsCount = priceOption.typeOf !== "top_up";

  if (priceOption.typeOf === "top_up") {
    participants = [1];
  }

  // Costs without voucher applied
  const baseCosts = calcBaseCosts(priceOption, participants);
  const trainers = event.trainers || [];
  return (
    <Wrapper>
      <CourseSummary>
        {titleImage(course) && <TitleImage src={titleImage(course)} />}
        <Heading3>{course.name}</Heading3>
        <Paragraph>
          <FormattedHTMLMessage
            id="components.Booking.intro"
            values={{ event: event.name, provider: provider.name }}
          />
          {trainers.length > 0 && (
            <Trainers>
              <FormattedMessage id="components.Course.trainers" />
              {trainers.join(", ")}
            </Trainers>
          )}
        </Paragraph>
      </CourseSummary>
      <ExpandButton onClick={() => setExpanded(nonMobile ? true : !expanded)}>
        <Icon name={expanded ? "chevron-down" : "chevron-up"} />
      </ExpandButton>
      {participants.length > 0 ? (
        <Table expanded={expanded} onClick={() => setExpanded(nonMobile ? true : !expanded)}>
          {expanded && (
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="description" />
                </th>
                <th width="34%">
                  <FormattedMessage id="price" />
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {expanded && (
              <tr>
                <td>
                  {showParticipantsCount && (
                    <FormattedMessage
                      values={{ amount: participants.length }}
                      id="booking.attributes.participantsCount"
                    />
                  )}
                  {priceOption && (
                    <p>
                      <PriceOptionName>
                        {priceOption.name}
                        {priceOption.participantMultiplier > 1 && (
                          <small>
                            {" ("}
                            <FormattedMessage
                              id="course.attributes.forParticiants"
                              values={{ count: priceOption.participantMultiplier }}
                            />
                            {")"}
                          </small>
                        )}
                      </PriceOptionName>
                    </p>
                  )}
                </td>
                <td>
                  {baseCosts > 0 ? (
                    <FormattedNumber value={baseCosts} {...currencyFormat(priceOption)} />
                  ) : (
                    "-"
                  )}

                  {priceOption.recurring && (
                    <div>
                      <PriceOptionName>
                        {priceOption.intervalCount < 2 && priceOption.intervalType ? (
                          <FormattedMessage id={`per.${priceOption.intervalType}`} />
                        ) : (
                          <FormattedMessage
                            id={`every.${priceOption.intervalType}`}
                            values={{ count: priceOption.intervalCount }}
                          />
                        )}
                        {priceOption.periodCount && (
                          <p>
                            <span>
                              {priceOption.periodCount * (priceOption.intervalCount || 1) + " "}
                            </span>
                            <FormattedMessage
                              id={`${priceOption.intervalType}.${
                                priceOption.periodCount > 1 ? "many" : "one"
                              }`}
                            />{" "}
                            <FormattedMessage id="contract.attributes.period" />
                          </p>
                        )}
                      </PriceOptionName>
                    </div>
                  )}
                </td>
              </tr>
            )}
            {expanded && voucher && (
              <tr>
                <td>
                  <strong>{voucher.code}</strong>
                  {" - "}
                  <FormattedMessage
                    id={`booking.attributes.${
                      voucher.unit === "cash" ? "voucher" : "voucherPercent"
                    }`}
                    values={{ percent: voucher.value }}
                  />
                  {baseCosts < voucher.minBookingAmount && (
                    <Small>
                      <br />
                      <FormattedMessage
                        id="booking.attributes.voucher.minBookingAmount"
                        values={{ amount: voucher.minBookingAmount }}
                      />
                    </Small>
                  )}
                </td>
                <td>
                  {baseCosts >= voucher.minBookingAmount ? (
                    <FormattedNumber
                      value={
                        (voucher.unit === "cash"
                          ? voucher.value
                          : (priceOption.price / 100) * (voucher.value / 100)) * -1
                      }
                      {...currencyFormat(priceOption)}
                    />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            )}
            {expanded && !course.serviceFeeIncluded && (
              <tr>
                <td>
                  <FormattedMessage id="booking.attributes.serviceFee" />
                </td>
                <td>
                  {serviceFee > 0 ? (
                    <FormattedNumber value={serviceFee} {...currencyFormat(priceOption)} />
                  ) : (
                    "-"
                  )}
                </td>
              </tr>
            )}
            {bookingItemValues &&
              bookingItemValues.map(({ bookingItemOption, quantity }) => (
                <tr key={bookingItemOption.id}>
                  <td>
                    <BookingItem title={bookingItemOption.name}>
                      {truncate(bookingItemOption.name, 20)}
                    </BookingItem>
                    {quantity > 1 && <small>{` x ${quantity}`}</small>}
                  </td>
                  <td>
                    <small>
                      <FormattedNumber
                        value={(quantity * bookingItemOption.price) / 100}
                        {...currencyFormat(priceOption)}
                      />
                    </small>
                  </td>
                </tr>
              ))}
            <tr>
              <td>
                <CostsTotal>
                  <FormattedMessage id="booking.attributes.totalCosts" />
                </CostsTotal>
              </td>
              <td>
                <CostsTotal>
                  {costs > 0 ? (
                    <FormattedNumber value={costs + serviceFee} {...currencyFormat(priceOption)} />
                  ) : (
                    "-"
                  )}
                </CostsTotal>
                <br />
                {costs > 0 && (
                  <VatRate>
                    <FormattedMessage id="booking.attributes.includingVAT" />
                  </VatRate>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <InstructionHint>
          <FormattedMessage id="components.Booking.addMinParticipant" />
        </InstructionHint>
      )}
    </Wrapper>
  );
};

Summary.propTypes = {
  participants: PropTypes.array.isRequired,
  costs: PropTypes.number.isRequired,
  serviceFee: PropTypes.number.isRequired,
  priceOption: PropTypes.object.isRequired,
  course: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  voucher: PropTypes.object,
  bookingItemValues: PropTypes.array,
};

Summary.defaultProps = {
  voucher: null,
  bookingItemValues: [],
};

export default Summary;
