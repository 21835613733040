import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedDate, injectIntl } from "react-intl";
import { scrollTop } from "../../utils/common";
import routes, { routeWithParams } from "../../routes";

import { ApiClient } from "../../utils/Api";
import { getSearchParams } from "../../utils/params";
import { containsOnlyNumbers } from "../../utils/common";
import ContactForm from "../../components/ContactForm";
import UserNav from "../../components/UserNav";
import {
  PageLayout,
  PageContent,
  PageTitle,
  Container,
  Loading,
  Button,
  Icon,
  Link,
  Paragraph,
} from "../../components/";
import LoginForm from "../../components/LoginForm";
import Page from "./Page";

const pageByParam = (pages, param) => {
  if (!pages) {
    return;
  }

  if (containsOnlyNumbers(param)) {
    return pages[parseInt(param) - 1];
  }

  return pages.find(page => page.id === param);
};

const Wrapper = styled(Container)`
  display: flex;
  min-height: 100vh;
  margin-top: 1.5rem;
  padding-top: 1rem;
  background-color: ${props => props.theme.gray100};
  border-radius: ${props => props.theme.borderRadius};

  @media (${props => props.theme.tabletScreen}) {
    padding-bottom: 3rem;
  }
`;

const LoadingWrapper = styled(Wrapper)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Error = styled.p`
  padding: 1rem 1.5rem;
  max-width: 480px;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  color: ${props => props.theme.danger};
  text-align: center;
`;

const PageLinks = styled.nav`
  flex-direction: column;
  max-width: 200px;
  min-width: 120px;
  flex: 1;
  margin-top: 1rem;
  margin-right: -${props => props.theme.borderRadius};
  background-color: ${props => props.theme.white};
  display: none;

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 2.5rem;
    display: flex;
  }
`;

const Content = styled.div`
  flex: 1;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (${props => props.theme.tabletScreen}) {
    background-color: ${props => props.theme.white};
    box-shadow: ${props => props.theme.boxShadow};
    border-radius: ${props => props.theme.borderRadius};
  }
`;

const PageButton = styled(Button)`
  text-align: left;
  box-shadow: ${props => props.theme.boxShadow};
  text-transform: none;

  &:hover {
    background-color: ${props => props.theme.light};
    box-shadow: ${props => props.theme.boxShadowLifted} !important;
    z-index: 1;
  }
`;

const FormWrapper = styled.div`
  max-width: 320px;
  margin: 2rem auto 0;
`;

const SignupLink = styled.small`
  display: block;
  text-align: center;
  margin-top: 0.5rem;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  padding: 1rem 0.5rem;
  border-top: 1px solid ${props => props.theme.gray200};

  @media (${props => props.theme.tabletScreen}) {
    padding: 1rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Provider = styled.p`
  display: flex;
  align-items: center;
`;

const ProviderName = styled.span`
  display: block;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 0.5rem;
`;

const Room = ({ provider, match, userData, token, intl }) => {
  const { id, roomToken } = match.params;

  const [room, setRoom] = useState(null);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [providerId] = useState(provider.id);

  const history = useHistory();

  const defaultPageParam = (getSearchParams() || {}).page;

  useEffect(() => {
    const getRoom = async () => {
      try {
        const data = await new ApiClient(token).room(providerId, id, roomToken);
        setRoom(data);
        setError(null);
      } catch (e) {
        if (e.isAxiosError) {
          const status = e.response.status;
          return setError(status || 500);
        }
        setError(500);
      }
    };

    getRoom();
    return () => {
      setRoom(null);
    };
  }, [id, roomToken, providerId, setRoom, token]);

  // Scroll top anytime the current page changes
  useEffect(() => {
    scrollTop();
  }, [currentPage]);

  useEffect(() => {
    if (!room) {
      return;
    }

    const defaultPage = pageByParam(room.pages, defaultPageParam);

    setCurrentPage(defaultPage || (room.pages || [])[0]);
    return () => {
      setCurrentPage(null);
    };
  }, [room, defaultPageParam]);

  const navigateTo = page => {
    history.push(`?page=${page.id}`);
  };

  if (error === 401) {
    return (
      <PageLayout>
        <UserNav />
        <LoadingWrapper>
          <Error>
            <FormattedMessage id={"pages.Room.sessionRequired"} />
            <FormWrapper>
              <LoginForm />
              <SignupLink>
                <NavLink to={routes.resetPassword}>
                  <FormattedMessage id="actions.forgotPassword" />
                </NavLink>
              </SignupLink>
            </FormWrapper>
          </Error>
        </LoadingWrapper>
      </PageLayout>
    );
  }

  if (error === 425) {
    return (
      <PageLayout>
        <UserNav />
        <LoadingWrapper>
          <Error>
            <FormattedMessage id={error === 425 ? "pages.Room.too_early" : "error"} />
          </Error>
        </LoadingWrapper>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <UserNav />
        <LoadingWrapper>
          <Error>
            <FormattedMessage id={error === 410 ? "pages.Room.expired" : "error"} />
          </Error>
        </LoadingWrapper>
      </PageLayout>
    );
  }

  if (!room) {
    return (
      <PageLayout>
        <UserNav />
        <LoadingWrapper>
          <Loading text={<FormattedMessage id="loading" />} />
        </LoadingWrapper>
      </PageLayout>
    );
  }

  const clean = provider.template === "clean";
  return (
    <PageLayout>
      <UserNav />
      <PageTitle
        title={room.name || ""}
        subTitle={provider.name}
        contact={provider.contacts[0]}
        logo={provider.logo}
        wallpaper={provider.template !== "standard"}
        clean={clean}
        small
      />
      <PageContent transparent>
        <Wrapper>
          <PageLinks>
            {room.pages.map(page => (
              <PageButton
                key={page.id}
                onClick={() => navigateTo(page)}
                style={{ marginBottom: "0.25rem", opacity: page.published ? 1 : 0.5 }}
                color={page === currentPage ? "primary" : clean ? "white" : "default"}
                glow={false}
              >
                {page.title}
                <span>
                  {page.publishAt && <Icon name="alarm-clock" size="small" spaced="left" />}
                  {page.contentFile && <Icon name="paperclip" size="small" spaced="left" />}
                  {page.vimeoVideoId && <Icon name="film" size="small" spaced="left" />}
                </span>
              </PageButton>
            ))}
          </PageLinks>
          <Content>
            {currentPage && <Page page={currentPage} room={room} />}
            <Footer>
              <section>
                <Provider>
                  <ProviderName>{provider.name}</ProviderName>
                  <ContactForm
                    provider={provider}
                    subject={intl.formatMessage(
                      { id: "pages.Room.messageSubject" },
                      { room: room.name, id: room.id },
                    )}
                  />
                </Provider>
                <Link
                  href={routeWithParams(routes.provider, { slug: provider.slug })}
                  target="blank"
                >{`kikudoo/${provider.slug}`}</Link>
              </section>
              <section>
                <Paragraph mono>
                  <Icon name="clock-rotate-left" size="small" spaced="right" />
                  {room.validDays > 0 ? (
                    <FormattedMessage
                      id="pages.Profile.onDemandValidUntil"
                      values={{ date: <FormattedDate value={room.validUntil} /> }}
                    />
                  ) : (
                    <FormattedMessage id="components.Course.onDemandEventsUnlimited" />
                  )}
                </Paragraph>
              </section>
            </Footer>
          </Content>
        </Wrapper>
      </PageContent>
    </PageLayout>
  );
};

Room.propTypes = {
  provider: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired,
};

export default injectIntl(Room);
