import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedHTMLMessage, FormattedMessage, FormattedNumber } from "react-intl";
import { Container, Button, Heading3, Overlay, Icon, Paragraph } from "../../components";
import { currencyFormat } from "../../utils/formatters";
import { lighten } from "polished";
import { COLOR_VARIATION } from "../../theme";

const Wrapper = styled(Container)`
  width: auto;
  background-color: ${props => props.theme.white};
  min-height: 100%;
  text-align: left;
  position: relative;
  padding: 0.5rem;
  border-radius: ${props => props.theme.borderRadius};
  margin: 0.5rem;

  @media (${props => props.theme.tabletScreen}) {
    padding: 2rem;
    margin: 2rem auto;
  }
`;

const SuccessMessage = styled.article`
  padding: 1rem;
  margin: 1rem 0 0;
  text-align: center;

  & > button {
    width: 100%;
    @media (${props => props.theme.tabletScreen}) {
      width: auto;
    }
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 2rem;
  }
`;

const Title = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  flex-direction: row;
`;

const TitleBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => lighten(COLOR_VARIATION * 3, props.theme.success)};
  border-radius: ${props => props.theme.borderRadiusLarge};
  padding: 1.25rem 1.75rem 1.5rem;
  position: relative;
  flex-direction: column;

  & > i {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  & > h3 {
    margin-bottom: 0;
  }

  @media (${props => props.theme.tabletScreen}) {
    flex-direction: row;

    & > i {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
`;

const BookingSummary = styled.div`
  display: block;
  text-align: center;
`;

const TitleImage = styled.div`
  background-image: url('${props => props.src}');
  background-color: ${props => props.theme.light};
  background-size: cover;
  width: 50%;
  margin: 0 auto 1rem;
  max-width: 320px;
  
  &:after {
    content: "";
    display: block;
    padding-top: 75%;
  }
  overflow: hidden;
  border-radius: ${props => props.theme.borderRadius};
`;

const SubTitle = styled.div`
  color: ${props => props.theme.dark};
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const Costs = styled.span`
  display: inline-block;
  font-family: ${props => props.theme.fontFamilyMonospace};
  font-size: ${props => props.theme.fontSizes.large};
  margin: 0.5rem 0 1.25rem 0;
  text-decoration: underline;
`;

const BillingNo = styled.div`
  position: absolute;
  bottom: -1.75rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  padding: 0.5rem 1rem;
  font-family: ${props => props.theme.fontFamilyMonospace};
  border-radius: 10rem;
  white-space: nowrap;
  box-shadow: ${props => props.theme.boxShadowSmall};
  @media (${props => props.theme.tabletScreen}) {
    bottom: -1.5rem;
  }
`;

const Success = ({ children, image, onClose, ctaText, booking }) => (
  <Overlay blackout onClose={onClose}>
    <Wrapper>
      <BookingSummary>
        {image && <TitleImage src={image} />}
        {children}
      </BookingSummary>
      <SuccessMessage>
        <Title>
          <TitleBanner>
            <Icon name="circle-check" color="success" size="huge" />
            <Heading3>
              <FormattedMessage id="components.Booking.success.title" />
            </Heading3>
            {booking?.billingNo && (
              <BillingNo>
                <FormattedMessage id="number" /> {booking.billingNo}
              </BillingNo>
            )}
          </TitleBanner>
        </Title>
        {booking?.costs > 0 && (
          <SubTitle>
            <small>
              <FormattedMessage id="components.Booking.success.totalPayment" />
            </small>
            <Costs>
              <FormattedNumber
                value={booking.costs + booking.serviceFee}
                {...currencyFormat(booking.priceOption)}
              />
            </Costs>
            <strong>
              <FormattedMessage id="components.Booking.success.paymentSucceeded" />
            </strong>
          </SubTitle>
        )}

        <Paragraph spaced centered>
          <FormattedHTMLMessage id="components.Booking.success.text" />
        </Paragraph>
        <br />
        <br />
        <Button color="success" onClick={onClose} glow>
          {ctaText || <FormattedMessage id="components.Booking.success.close" />}
        </Button>
      </SuccessMessage>
    </Wrapper>
  </Overlay>
);

Success.propTypes = {
  onClose: PropTypes.func.isRequired,
  ctaText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  booking: PropTypes.object,
};

Success.defaultProps = {
  ctaText: null,
  children: null,
  booking: null,
};

export default Success;
