import React from "react";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import { PageLayout, Icon, Button } from "../../components";
import Loading from "../../components/Loading";

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;

const Message = styled.p`
  padding: 2rem;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: ${props => props.theme.boxShadow};
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
`;

const FailOrLoading = ({ failure, returnUrl }) => (
  <PageLayout>
    <LoadingWrapper>
      {failure ? (
        <Message>
          <span style={{ marginBottom: "1rem", display: "flex", alignItems: "center" }}>
            <Icon name="add" color="danger" direction={45} />
            <span style={{ marginLeft: "1rem", padding: "4px 0" }}>
              <FormattedMessage id={`components.Booking.redirectFailure.${failure || "generic"}`} />
            </span>
          </span>

          <Button color="info" to={returnUrl}>
            <FormattedMessage id="components.Booking.success.close" />
          </Button>
        </Message>
      ) : (
        <Loading text={<FormattedMessage id="loading" />} />
      )}
    </LoadingWrapper>
  </PageLayout>
);

export default FailOrLoading;
